/* eslint-disable */

import axios from 'axios'
const hostname = window.location.hostname
let baseURL = 'https://api.florinz.com/'

if (hostname !== 'qr.florinz.com') {
  const subdomainMappings = {
    test: 'https://staging.florinz.com/',
    'new-qr': 'https://api-staging-v2.florinz.com/',
    '127.0.0.1': 'https://api-staging-v2.florinz.com/',
    localhost: 'https://api-staging-v2.florinz.com/',
    'qr.nwselk.com': 'https://florinz.nwselk.com/',
    'florinz.hottaste.com.sa': 'https://api.hottaste.com.sa/',
    'florinz.labella-ksa.com': 'https://api.labella-ksa.com/',
    'florinz.oxyhealth.com.sa': 'https://api.oxyhealth.com.sa/',
    'new-qr.florinz.com': 'https://api-staging-v2.florinz.com/',
    'temp-qr.florinz.com': 'https://temp-api.florinz.com/',
    'florinz.lomar.sa': 'https://api.lomar.sa/',
    'florinz.drtom-clinic.com': 'https://api.drtom-clinic.com/',
    'florinz.krasa-sa.com': 'https://api.krasa-sa.com/'
  }

  const matchingSubdomain = Object.keys(subdomainMappings).find((subdomain) =>
    hostname.includes(subdomain)
  )

  if (matchingSubdomain) {
    baseURL = subdomainMappings[matchingSubdomain]
  } else if (hostname.startsWith('florinz.')) {
    const modifiedHostname = hostname.replace(/^florinz\./, '')
    baseURL = `https://api.${modifiedHostname}/`
  } else {
    const modifiedHostname = hostname.replace(/umrah\.|qr\./g, '')
    baseURL = `https://api.${modifiedHostname}/`
  }
}

const request = axios.create({
  baseURL,
  timeout: 15000
})

request.defaults.withCredentials = true

export default request
